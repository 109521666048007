import Papyrus from '../../components/Papyrus'
import { Col, Row, Image, Alert, Modal, Button, Form } from 'react-bootstrap'
import { useCookies } from 'react-cookie'
import React, { useContext, useEffect, useState } from 'react'
import useAppContext from '../../lib/app_context'
import './index.css'
import { ethers } from 'ethers'
import useWeb3Hooks from '../../hook/web3Hooks'
import Countdown from 'react-countdown'
import mintActive from './mint-button-active.gif'
import mintInActive from './mint-button-inactive.png'
import StoneButton from "../../components/StoneButton";
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { Link } from 'react-router-dom'
function WhiteListMint() {
  const web3Hooks = useWeb3Hooks()
  const [_, AppContext] = useAppContext()
  const { connected, wallet, metamaskInstalled, connectWeb3, disconnectWeb3 } = useContext(AppContext);
  const [walletConnect, setWalletConnect] = useState(false)
  const daiAddress = process.env.REACT_APP_WHITELIST_MINTING_ADDRESS
  const network_id = process.env.REACT_APP_NETWORK_ID
  const api = process.env.REACT_APP_MAIN_API
  const [switchNetwork, setSwitchNetwork] = useState(false)
  const [connectedCookie, setConnectedCookie] = useCookies(['connected'])
  const [walletCookie, setWalletCookie] = useCookies(['wallet'])
  const [canMint, setCanMint] = useState(false)
  const [requireSig, setRequireSig] = useState(false)
  const [sign, setSign] = useState('0x0')
  const [requestFinished, setRequestFinished] = useState(false)
  const [launchDate, setLaunchDate] = useState(new Date("2022-12-24T17:00:00.000+00:00"))
  const [show, setShow] = useState(false);
  const [amount, setAmount] = useState(3);
  const [message, setMessage] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const location = useLocation()

  const isWhitelisted = async () => {

    const accounts = await web3Hooks.getAccounts()
    if (accounts.length > 0) {
      fetch(api + 'api/v1/whitelists/' + accounts[0])
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 404) {
            setCanMint(false)
          } else {
            setCanMint(true);
            setMessage(data.message);
          }
          setRequestFinished(true);
        })
    }
  }

  const checkWhitelist = async () => {
    isWhitelisted()
    console.log('Info:', connected, canMint);
  }

  const stripe = async () => {
    const accounts = await web3Hooks.getAccounts();
    const sendSignatureOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ payment: { amount: amount, account: accounts[0] } }),
    };
    fetch(api + "api/v1/checkouts/", sendSignatureOptions)
      .then((response) => response.json())
      .then(async (data) => {
        if (data.status == 200) {
          window.location = data.session
        }
      });
  }

  const mint = async () => {
    let inject = false
    if (!connected) {
      await connectWeb3();
      inject = true;
    }
    if (connected || inject) {
        await web3Hooks.mintWhitelist("0x", amount);
      } else {
        alert("Connect to Whitelist");
      }
    }
  

  useEffect(() => {
    document.title = 'The Imps - Minting Page'
    const params = queryString.parse(location.search)
    if (connected) {
      checkWhitelist();
    }
    if (canMint) {
      if (params.ogSale) {
        const date = new Date(params.ogSale * 1000)
        console.log(date)
        web3Hooks.setOgSale(params.ogSale)
        setLaunchDate(date)
      }
    }
  }, [connected, canMint])

  const openPopup = () => {
    handleShow();
  }

  const decrease = () => {
    if (amount > 1) {
      setAmount(amount - 1);
    }
    if (amount == NaN) {
      setAmount(1)
    }
  }

  const increase = () => {
    if (amount < 3) {
      setAmount(amount + 1);
    }
    if (amount == NaN) {
      setAmount(3)
    }
  }

  const setValue = (value) => {
    if (value > 3) {
      setAmount(3)
      return
    }
    if (value < 1) {
      setAmount(1)
      return
    }
    setAmount(value);
  }

  const whitelistChecker = async () => {
    if (!connected) {
      await connectWeb3();
    }
    checkWhitelist();
  }


  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <div >
        <span className="papyrus-sub-title" >Mint</span><br />
        <a onClick={openPopup}>
          <Image src={mintActive} fluid />
        </a>
      </div>
    } else {
      return (<>
        <span className="papyrus-sub-title" >{('0' + days).slice(-2)} days and {('0' + hours).slice(-2)}:{('0' + minutes).slice(-2)}:{('0' + seconds).slice(-2)}</span>
        <br /><Image src={mintInActive} fluid />
      </>)

    }
  }

  return (<div className="inner-content">

    <Row className="justify-content-md-center">
      <Col xs="12" md="6" className="text-center">
        <br />
        <br />
        <br />
        <br />
          <h1 className="papyrus-sub-title">Sold out</h1>
        <br />
        <br />

      

      </Col>
    </Row>

  </div>)
}

export default WhiteListMint
