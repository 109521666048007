import image from './seventhCircle.gif'
import ConainerInView from '../ContainerInView'
function SeventhCircle () {
  return (<ConainerInView image={image} buttonText={'Fight'} target={'/cerberus'}>
        <h2 className="titleWhite">3rd Circle: Eternal Rain</h2>
        <p className="text">The Third Circle is filled with cold rain that’s eternal,<br />
            And the barking of Cerebus, a hound quite infernal.<br />
            Beelzebub loathes the gluttonous souls,<br />
            Stuck in their place, and beyond all console.<br />
            With your Impgang, silence the mutt,<br />
            For a golden key NFT, yours to be cut.<br /><br />
        </p>
    </ConainerInView>)
}

export default SeventhCircle
