import Navigation from '../Navigation'
import Footer from '../Footer'
import { useRef, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import SplashIntro from '../SplashIntro'
import _flake from './snowflake.png'
import Snowfall from 'react-snowfall'

function ApplicationWrapper ({ children, player }) {
  const location = useLocation()
  const [firstTime, setFirstTime] = useState(true)
  const [visibleSplash, setVisibleSplash] = useState(true)
  const [contentClass, setContentClass] = useState('content')
  const snowflake = document.createElement('img')
  snowflake.src = _flake

  const flakes=[snowflake]
  useEffect(() => {
    if (location.pathname == '/' && firstTime) {
      setVisibleSplash(true)
    } else {
      setVisibleSplash(false)
    }

    if(location.pathname=== '/'){
      setContentClass('content startpageContent')
    }else{
      setContentClass('content')

    }

    setFirstTime(false)
  }, [location])

  useRef(() => {
    window.scrollBy({ top: 0, behavior: 'instantly' })
  })

  return (<>
    {visibleSplash ? <SplashIntro /> : null}
    <Navigation player={player}></Navigation>
    <div className={contentClass}>
      <div className="backgroundImage"></div>
      <div className="stonesTop"></div>
      <Snowfall
      color={"#dee4fd"}
      snowflakeCount={200}
      radius={[0.5, 20]}
      speed={[-0.5, 2]}
      wind={[0.5, 3]}
      rotationSpeed={[-3.5, 5]}
      images={flakes}
    />


      {children}
    </div>
    <Footer player={player}></Footer>
  </>)
}

export default ApplicationWrapper
