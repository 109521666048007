import React from 'react'

import "./index.css";

function ErrorPageWrapper({ children, player }) {

  return (
    <React.Fragment>
      <div className="error-fullpage">
        {children}
      </div>
    </React.Fragment>)
}

export default ErrorPageWrapper
