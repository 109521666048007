import image from './eigthCircle.gif'
import ConainerInView from '../ContainerInView'

function EigthCircle () {
  return (<ConainerInView image={image} buttonText={'Apply'} target={'/advocat'}>
        <h2 className="titleWhite">2nd Circle: Valley of the winds</h2>
        <p className="text">
            Passions led many to stray from their path,<br />
            Asmodeus waits in the aftermath.<br />
            The winds shred the lustful and lewd,<br />
            Their judgment on Earth so heavily skewed.<br />
            Create your own character, and you shall see.<br />
            A devil's advocate is what you can be.<br /><br />
        </p>
    </ConainerInView>)
}

export default EigthCircle
