import { useEffect, useState } from 'react'
import { Alert, Image } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import stoneBackground from '../StoneButton/stone.png'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import enterDiscord from './enter_discord.gif'
import StoneButton from '../StoneButton'
import flame from './43.gif'
const WhitelistForm = () => {
  const location = useLocation()
  const [error, setError] = useState(false)
  const [referral, setReferral] = useState(undefined)
  const [validEthereum, setValidEthereum] = useState(false)
  const [requiredField, setRequiredField] = useState(false)
  const [correctEmailFormat, setCorrectEmailFormat] = useState(false)
  const [noWallet, setNoWallet] = useState(false)
  const [connectionError, setConnectionError] = useState(false)
  const [email, setEmail] = useState('')
  const [twitter, setTwitter] = useState('')
  const [discord, setDiscord] = useState('')
  const [wallet, setWallet] = useState('')
  const [extra, setExtra] = useState('')
  const [source, setSource] = useState('no-source')
  const [formSubmitted, setFormSubmitted] = useState(false)

  useEffect(() => {
    setSource(location.hash)
    const params = queryString.parse(location.search)
    if (params.referral) {
      setReferral(params.referral)
    }
  }, [location])

  function isValidEmail (text) {
    return /\S+@\S+\.\S+/.test(text)
  }

  function isValidAddress (address) {
    return /0x[a-fA-F0-9]{40}/.test(address)
  }

  const submitAction = () => {
    setConnectionError(false)
    const testRequiredField = (email.length === 0 && twitter.length === discord.length && email.length == discord.length)
    setRequiredField(testRequiredField)
    const testEmailFormat = (email.length > 0 && !isValidEmail(email))
    setCorrectEmailFormat(testEmailFormat)
    const testWalletExistens = wallet.length == 0
    setNoWallet(testWalletExistens)
    const testAddress = (wallet.length > 0 && !isValidAddress(wallet))
    setValidEthereum(testAddress)

    const errorTest = testWalletExistens || testRequiredField || testEmailFormat || testAddress

    setError(errorTest)
    console.log(errorTest)
    if (!errorTest) {
      const host = process.env.REACT_APP_MAIN_API
      const api = '/api/v1/whitelists/'
      const params = {
        whitelist: {
          address: wallet,
          twitter,
          discord,
          email,
          extra,
          source,
          parent_ref: referral
        }
      }
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params)
      }
      fetch(host + api, requestOptions)
        .then(() => {
          setFormSubmitted(true)
        })
        .catch(() => {
          setConnectionError(true)
          setError(true)
          setFormSubmitted(false)
        })
    }
  }

  return (
        <Form>
            {formSubmitted &&
                <div className="text-center">
                    <br /><br />
                    <h4 className="snap-font text-center">Your Implist application< br /> has been sent.
                        <Image src={flame} width="40px" />
                    </h4>
                    <p style={{ fontWeight: 'bold', textAlign: 'left' }}>
                        But you are not done yet.

                        <br />
                        Join our Discord channel and finalize the application process by posting <span style={{fontSize: '24px'}}>👺</span> in general chat.
                    </p>
                    <a href="https://discord.com/invite/fallenangelsclub" target="_blank">
                      <Image src={enterDiscord} fluid />
                    </a>
                    <br />
                    <br />
                    <p style={{ fontWeight: 'bold' }}>
                      Participate in our partner program and invite your friends to help for a chance to get your stake of $25.000!
                    </p><br />
                    <StoneButton url={'/referral?wallet=' + wallet} smaller={true}>Join the affiliate program</StoneButton>
                    <br />
                    <br />
                    <br />

                </div>}

            {
                error && <Alert variant="danger">
                    The Form has errors.
                    <ul>
                        {validEthereum && <li>The Address is not an Ethereum Address</li>}
                        {noWallet && <li>Please Type your Ethereum Wallet</li>}
                        {requiredField && <li>You have to enter your email, your Twitter username or your Discord username.</li>}
                        {correctEmailFormat && <li>Your email is not valid.</li>}
                        {connectionError && <li>There is a problem with the submission please try again or later</li>}
                    </ul>
                </Alert>
            }
            {!formSubmitted && <>
                <h2 className="title white-text">FILL YOUR DETAILS FOR A CHANGE TO CLAIM A IMPLIST SPOT</h2>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="papyrus-text">Discord Tag</Form.Label>
                    <Form.Control type="text" placeholder="Discord Tag" value={discord} onChange={(e) => setDiscord(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="papyrus-text">Twitter Handle</Form.Label>
                    <Form.Control type="text" placeholder="Twitter Handle" value={twitter} onChange={(e) => setTwitter(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="papyrus-text">Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="papyrus-text">Eth Wallet Address</Form.Label>
                    <Form.Control type="text" placeholder="Your Ethereum Wallet" value={wallet} onChange={(e) => { setWallet(e.target.value) }} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="papyrus-text">Your Comment</Form.Label>
                    <Form.Control type="text" placeholder="Feel free say what ever you like." value={extra} onChange={(e) => { setExtra(e.target.value) }} />
                </Form.Group>
                <br />
                <p className="text-right">
                    <a className="stoneButton" style={{ backgroundImage: `url(${stoneBackground})` }} onClick={submitAction}>
                        Submit Now
                    </a>
                </p>
                <br />
                <br />
            </>}
        </Form>
  )
}

export default WhitelistForm
