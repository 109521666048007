import './index.css'
import { Col, Row, Image, Button } from 'react-bootstrap'
import StoneButton from '../StoneButton'
// <-- import styles to be used
function Frame ({ data, canMint = false }) {
  return (<>
        <Col lg="4" md="6" xs="12" >
            <Row className="borderFrame">
                <Col lg="12">
                    <h3>{data.name}</h3>
                </Col>
                <Col lg="12">
                    <Image src={data.image} fluid />
                </Col>
                <Col lg="12" className='textarea'>
                    <p dangerouslySetInnerHTML={{ __html: data.description }} />
                </Col>
                {canMint
                  ? (
                    <Col lg="12" className="mb-3 mintarea">
                        <Row>
                        <Col xs="6" className="pt-3">
                        <StoneButton>Mint</StoneButton>
                        </Col>
                        <Col xs="6" className="text-right">
                        {data.linkedIn ? <><a className="colorized" href={data.linkedIn} target="_blank" rel="noreferrer"><i className="fa-brands fa-linkedin"></i></a>&nbsp;&nbsp;</> : null}
                        {data.twitter ? <><a className="colorized" href={data.twitter} target="_blank" rel="noreferrer"><i className="fa-brands fa-twitter"></i></a>&nbsp;&nbsp;</> : null}
                        {data.instagram ? <><a className="colorized" href={data.instagram} target="_blank" rel="noreferrer"><i className="fa-brands fa-instagram"></i></a>&nbsp;&nbsp;</> : null}
                        {data.youtube ? <><a className="colorized" href={data.youtube} target="_blank" rel="noreferrer"><i className="fa-brands fa-youtube"></i></a>&nbsp;&nbsp;</> : null}
                        {data.art ? <><a className="colorized" href={data.art} target="_blank" rel="noreferrer"><i className="fa-brands fa-artstation"></i></a>&nbsp;&nbsp;</> : null}
                        </Col>
                        </Row>
                    </Col>
                    )
                  : <Col lg="12" className="mb-2">
                        {data.linkedIn ? <><a className="colorized" href={data.linkedIn} target="_blank" rel="noreferrer"><i className="fa-brands fa-linkedin"></i></a>&nbsp;&nbsp;</> : null}
                        {data.twitter ? <><a className="colorized" href={data.twitter} target="_blank" rel="noreferrer"><i className="fa-brands fa-twitter"></i></a>&nbsp;&nbsp;</> : null}
                        {data.instagram ? <><a className="colorized" href={data.instagram} target="_blank" rel="noreferrer"><i className="fa-brands fa-instagram"></i></a>&nbsp;&nbsp;</> : null}
                        {data.youtube ? <><a className="colorized" href={data.youtube} target="_blank" rel="noreferrer"><i className="fa-brands fa-youtube"></i></a>&nbsp;&nbsp;</> : null}
                        {data.art ? <><a className="colorized" href={data.art} target="_blank" rel="noreferrer"><i className="fa-brands fa-artstation"></i></a>&nbsp;&nbsp;</> : null}
                        &nbsp;
                    </Col>
                }
            </Row>
        </Col>
    </>)
}

export default Frame
