import { ethers } from 'ethers'
import whitelistMinterAbi from '../abi/whitelistMinter'
import minterAbi from './minter'
import { toast } from "react-toastify";

const useWeb3Hooks = () => {
  const daiAddress = process.env.REACT_APP_MINTING_ADDRESS;
  const netWorkId = process.env.REACT_APP_NETWORK_ID

  const getMinter = () => {
    return new ethers.Contract(daiAddress, minterAbi, this.getProvider())
  }

  const mint = async (amount) => {
    const provider = getProvider()
    const signer = provider.getSigner()
    const daiContract = new ethers.Contract(daiAddress, minterAbi, signer)
    const accounts = getAccounts()
    await daiContract.mint(amount)
  }

  const mintWhitelist = async (sign, amount, callback) => {
    const provider = getProvider()
    const signer = provider.getSigner()
    console.log("Address", daiAddress)
    const daiContract = new ethers.Contract(daiAddress, minterAbi, signer)
    const accounts = await getAccounts()
    const message = accounts[0].toLowerCase();
    let salesPrice = ethers.utils.parseUnits('666', 14)
    const total = salesPrice.mul(ethers.BigNumber.from(amount));
    daiContract.mint(amount, {
      value: total,
      from: accounts[0],
    }).then(() => {
      toast.info('Imps is minted');
    }).catch((e) => {
      toast.error(e.message)
    })
  }

  const getProvider = () => {
    return new ethers.providers.Web3Provider(window.ethereum)
  }

  const getAccounts = async () => {
    return await getProvider().send('eth_requestAccounts', [])
  }

  const signText = async (text, callback) => {
    const signer = getProvider().getSigner()
    signer.signMessage(text).then(callback)
  }

  const canMint = async (address) => {
    const provider = getProvider()
    const signer = provider.getSigner()
    const daiContract = new ethers.Contract(daiAddress, minterAbi, signer)
    return await daiContract.canMint(address)
  }


  //** REMOVE LATER */
  const setOgSale = async (time) => {
    const provider = getProvider()
    const signer = provider.getSigner()
    const daiContract = new ethers.Contract(daiAddress, minterAbi, signer)
    await daiContract.setOgSale(time)
  }


  return {

    getMinter,
    mint,
    mintWhitelist,
    getProvider,
    setOgSale,
    getAccounts,

    signText,
    canMint
  }
}

export default useWeb3Hooks
