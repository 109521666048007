import { Alert, Row, Col, Image } from 'react-bootstrap'
import Papyrus from '../../components/Papyrus'
import { useNavigate } from 'react-router-dom'
import imp from './moving-imp.gif'
import React, { useEffect, useContext } from 'react'
import StoneButton from '../../components/StoneButton'
import useAppContext from '../../lib/app_context'

function Carve() {
  const [_, AppContext] = useAppContext();
  const { connected, wallet, metamaskInstalled, connectWeb3, disconnectWeb3 } = useContext(AppContext);

  useEffect(() => {
    document.title = 'The Imps - Your Imp Idol'
  }, [connected])



  return (<>
    <Papyrus data="0" nav="/hell/#sixthCircle" >
      <br />
      <Row>
        <Col xs="12 text-center">
          <h1 className="papyrus-title">Imp Idol</h1>
          <p>

            Carve a fully customized and despicable Imp Idol, <br />
            and have it delivered directly to you!
            <br /><br />
            <Image src={imp} fluid width="150px" />
          </p>
          <br />
          {!connected ? <>
            <StoneButton onClick={connectWeb3}>Carve Idol</StoneButton>
          </> : <>
            <Alert variant="danger">No The Imps NFT in wallet detected in rot</Alert>
          </>}
        </Col>
      </Row>
    </Papyrus>
  </>)
}

export default Carve
