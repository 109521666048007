import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import topf from './topf.gif';

const Success = () => {
    return (<div className="inner-content">
      <Row className="justify-content-md-center">
            <Col xs="12" md="6" className="text-center">
                <br />
                <br />
                <br />
                <h1 className="titleWhite">Infernal Success:<br />The Imps Are Now Yours to Collect!</h1>
                <Image src={topf} width="400px" />
                <p className="text-white">
                    See ya later, Satan! Congratulations on successfully acquiring your very own Imp. These virtual collectibles are highly coveted, and we hope you feel as proud possessing them as we did creating them. Yours will be available in your wallet soon.
                </p>

                <h3 className="text-white">
                    In the meantime, here's some key information:
                </h3>

                <ul className="text-white" style={{ listStyle: 'none' }}>
                    <li>Your Imps' identity will be revealed in two weeks. After they waited centuries to get out of Hell, we know you can handle a couple of more weeks of mystery and anticipation!</li>
                    <li>24 hours from now, you will be able to mint Devil's Lean, a special NFT exclusive to Imp holders that can be used to purchase additional exclusive items.</li>
                    <li>If you were brave enough to take possession of three Imps, you have the ability to mint a rare Golden Key, which can be used to "unlock" special in-game rewards.</li>
                    <li>Your Imps can be staked to collect the most valuable currency available: Blood Diamonds. With these tokens, you can fight and purge other Imps in the Arena.</li>
                    <li>A word of caution: if you list your Imp for sale on Opensea for less than 0.1666 ETH, you will be exposed to being purged by other players, and could lose your Imp forever. Take special care to set a price higher than 0.1666 ETH to avoid this devastating fate...
                        Congratulations again, and welcome to the ultra-select Imp Holder community! We know you’ve been DYING to get in!</li>
                </ul>
            </Col>
        </Row>
    </div>)
}


export default Success;


