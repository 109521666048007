import './App.css'
import ApplicationWrapper from './components/ApplicationWrapper'
import ErrorPageWrapper from './components/ErrorPageWrapper'
import Startpage from './pages/Startpage'
import Hell from './pages/Hell'
import React, { useRef, useState, useEffect } from 'react'
import Provenance from './pages/Provenance'
import Impress from './pages/Impress'
import Disclaimer from './pages/disclaimer'
import useAppContext from './lib/app_context'
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  useLocation
} from 'react-router-dom'
import Loader from './components/Loader'

import Arena from './pages/Arena'
import Carve from './pages/Carve'
import Impfluencer from './pages/Impfluencer'
import Cerberus from './pages/Cerberus'
import Advocat from './pages/Advocat'
import WhiteListMint from './pages/WhitelistMint'
import Agreement from './pages/Agreement'
import sounds from './music.mp3'
import WhitelistApplication from './pages/WhitelistApplication'
import WhitelistList from './pages/WhitelistList'
import Refereal from './pages/Referral'
import Success from './pages/Success';
import Cancle from './pages/Cancle'
import { Web3ContextProvider } from 'ethers-react'
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const player = new Audio(sounds)

player.addEventListener('ended', function () {
  this.currentTime = 0
  this.play()
}, false)
const router = createBrowserRouter([
  {
    path: '/',
    element: <ApplicationWrapper player={player}><Startpage /></ApplicationWrapper>
  },
  {
    path: '/legal',
    element: <ApplicationWrapper player={player}><Impress /></ApplicationWrapper>
  },
  {
    path: '/disclaimer',
    element: <ApplicationWrapper player={player}><Disclaimer /></ApplicationWrapper>
  },
  {
    path: '/hell',
    element: <ApplicationWrapper player={player}><Hell /></ApplicationWrapper>
  },
  {
    path: '/agreement',
    element: <ApplicationWrapper player={player}><Agreement /></ApplicationWrapper>
  },
  {
    path: '/carve',
    element: <ApplicationWrapper player={player}><Carve /></ApplicationWrapper>
  },
  {
    path: '/arena',
    element: <ApplicationWrapper player={player}><Arena /></ApplicationWrapper>
  },
  {
    path: '/impfluencer',
    element: <ApplicationWrapper player={player}><Impfluencer /></ApplicationWrapper>
  },
  {
    path: '/provenance',
    element: <ApplicationWrapper player={player}><Provenance /></ApplicationWrapper>
  },
  {
    path: '/cerberus',
    element: <ApplicationWrapper player={player}><Cerberus /></ApplicationWrapper>
  },
  {
    path: '/advocat',
    element: <ApplicationWrapper player={player}><Advocat /></ApplicationWrapper>
  },
  {
    path: '/whitelistMint',
    element: <ApplicationWrapper player={player}><WhiteListMint /></ApplicationWrapper>
  },
  {
    path: '/mint',
    element: <ApplicationWrapper player={player}><WhiteListMint /></ApplicationWrapper>
  },
  {
    path: '/whitelistForm',
    element: <ApplicationWrapper player={player}><WhitelistApplication /></ApplicationWrapper>
  },
  {
    path: '/walehack',
    element: <ApplicationWrapper player={player}><WhitelistList /></ApplicationWrapper>
  },
  {
    path: '/referral',
    element: <ApplicationWrapper player={player}><Refereal /></ApplicationWrapper>
  },
  {
    path: '/success',
    element: <ApplicationWrapper player={player}><Success /></ApplicationWrapper>
  },
  {
    path: '/cancel',
    element: <ErrorPageWrapper player={player}><Cancle /></ErrorPageWrapper>
  }
])

function App() {
  const [AppContextProvider, AppContext] = useAppContext()

  return (
    <>
      <Web3ContextProvider>
        <Loader />
        <AppContextProvider value={AppContext}>
          <RouterProvider router={router} />
          <ToastContainer
            position="bottom-right"
            hideProgressBar={true}
            newestOnTop={true}
            autoClose={3000}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </AppContextProvider>
      </Web3ContextProvider>
    </>
  )
}

export default App
