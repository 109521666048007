import React, { useContext, useEffect, useState } from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import './index.css'
import mint from './mint.gif'
import arena from './arena.gif'
import warlock from './warlock.gif'
import logo from './logo.png'
import discord from './discordicon.gif'
import twitter from './twittericon.gif'
import opensea from './openseaicon.gif'
import youtube from './tvicon.gif'
import whitepaper from './whitepaper.gif'
import f3 from './3.gif'
import soundStopped from './music_off.png'
import soundPlaying from './music_on.gif'
import { Image } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import useAppContext from '../../lib/app_context'

function Navigation({ child, player }) {
  const [_, AppContext] = useAppContext();
  const { connected, wallet, metamaskInstalled, connectWeb3, disconnectWeb3 } = useContext(AppContext);
  const [soundPlay, setSoundPlay] = useState(false)
  const [userIneract, setUserIneract] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies(['userInteraction'])
  const [navState, setNavState] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  const [address, setAddress] = useState('Connect')

  useEffect(() => {
    if ((cookies.userInteraction === undefined || cookies.userInteraction === false) && player && location && !soundPlay && !userIneract) {
      if (location.pathname === '/hell' && player) {
        console.log(location.pathname)
        player.play().then((result) => {
          setSoundPlay(true)
        })
      }
    }
    if (connected) {
      const a = '0' + wallet.substring(1, 5) + '...' + wallet.substring(wallet.length - 4, wallet.length - 1)
      setAddress(a)
    } else {
      setAddress('Connect')
    }
  }, [soundPlay, location, navState, connected])

  const toggle = async () => {
    setNavState(false);
    if (connected) {
      disconnectWeb3();
    } else {
      connectWeb3()
    }
  }

  function toggleNav() {
    setNavState(!navState)
  }

  function togglePlayer(e) {
    setCookie('userInteraction', true, { path: '/' })
    setUserIneract(true)
    if (soundPlay) {
      player.pause()
      setSoundPlay(false)
    } else {
      player.play().then(() => {
        setSoundPlay(true)
      })
    }
  }

  function rootLink() {
    setNavState(false)
    navigate('/')
  }

  function whiteListMint() {
    setNavState(false)
    navigate('/mint')
  }

  function arenaLink() {
    setNavState(false)
    navigate('/arena')
  }

  function warlockLink() {
    setNavState(false)
    window.open('https://releeze.com/product/infernal-t-shirt-fallen-angels-club-RZ1435.1/', '_blank')
  }

  return (
    <Navbar collapseOnSelect fixed="top" expand="lg" bg="dark" variant="dark" className="theImps bricks"
      onToggle={toggleNav}
      expanded={navState}>
      <Container>
        <Navbar.Brand onClick={rootLink} className="pointer">
          <img src={logo} alt="Devils Lean" target="_blank" height="60px" />
        </Navbar.Brand>
        <Navbar.Brand onClick={toggle} className="pointer onlyMobile">
          <img src="/wallet.svg" alt="Wallet"  width="20px" />
          <span className="wallet-connect-text">{address}</span>
        </Navbar.Brand>

        <div className="onlyMobile">
          <Nav.Item className='inline-nav mobile-margin-right'>
            <Nav.Link className="soundplayer" onClick={togglePlayer}>
              {soundPlay
                ? <>
                  <Image src={soundPlaying} />
                </>
                : <>
                  <Image src={soundStopped} />
                </>}
            </Nav.Link>
          </Nav.Item>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        </div>
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
          <Nav
            className="me-auto my-2 my-lg-0"
            navbar
          >

            <Nav.Item className="hideOnMobile navbarIcons">
              <Nav.Link href="/whitepaper.pdf" className="tvIcon" target="_blank">
                <Image src={whitepaper} />
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Nav>
            <Nav.Link onClick={() => { whiteListMint() }} className="navbarIcons arena mintLink">
              <img src={mint} alt="Devils Lean" target="_blank" />
              <div className="onlyMobile subText">
                <br />
                Mint your Imp
              </div>
            </Nav.Link>
            <Nav.Link eventKey={2} onClick={arenaLink} className="navbarIcons arena">
              <img src={arena} alt="Arena" target="_blank" />
              <div className="onlyMobile subText">
                <br />
                Fight with your Imps
              </div>
            </Nav.Link>
            <Nav.Link eventKey={2} onClick={warlockLink} className="navbarIcons warlock">
              <img src={warlock} alt="Warlock" target="_blank" />
              <div className="onlyMobile subText">
                <br />
                Get your Imp Merch
              </div>
            </Nav.Link>
            <Nav.Link eventKey={3} onClick={toggle} className="navbarIcons warlock nooverflow hideOnMobile">
              <img src="/wallet.svg" alt="Wallet" />
              <span className="wallet-connect-text">{address}</span>
            </Nav.Link>
            <span className="onlyMobile"><br />

              <Nav.Link href="/whitepaper.pdf" className="tvIcon" target="_blank" style={{textAlign:'center'}}>
                <Image src={whitepaper} fluid style={{maxWidth: "180px"}}/>
                <div className="onlyMobile subText">
                <br />
                Read all about the Imps
              </div>
              </Nav.Link>
              <br />
              <br />
              <br />
            </span>

            <Nav.Item className="onlyMobile footer-wrapper">
              <div className="footer-toolbar">
                <Nav.Item className="onlyMobile">
                  <Nav.Link href="https://discord.gg/fallenangelsclub" target="_blank">
                    <Image src={discord} width="30px" />
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item className="onlyMobile">
                  <Nav.Link href="https://twitter.com/theimpsnft" target="_blank">
                    <Image src={twitter} width="30px" />
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item className="onlyMobile">
                  <Nav.Link href="https://opensea.io/collection/the-imps-fallen-angels-club" target="_blank"><Image src={opensea} width="30px" />
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item className="onlyMobile">
                  <Nav.Link href="https://www.youtube.com/channel/UCZMBM5HLunOQlHaVKtuIM5Q" className="tvIcon" target="_blank">
                    <Image src={youtube} width="30px" /></Nav.Link>
                </Nav.Item>

                <Nav.Link href="https://futurew3b.de/" target="_blank" className="onlyMobile tvIcon" >
                  <Image src={f3} width="30px" />
                </Nav.Link>

              </div>


            </Nav.Item>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Navigation
