
import fourthCircle from './fourthCircle.gif'
import ConainerInView from '../ContainerInView'

function FourthCircle () {
  return (
        <ConainerInView image={fourthCircle} buttonText={'Create'} target={'/carve'}>
            <h2 className="titleWhite">6th Circle: Fire cementerey</h2>
            <p className="text">Can you imagine burning within a tomb?<br />
                In the Sixth Circle you’d face this doom.<br />
                A fire cemetery for those who reject<br />
                All of God’s teachings, and give no respect.<br />
                Custom idols and sculptures are what await,<br />
                3D-printed for you once you pass through this gate.<br /><br />
            </p>
        </ConainerInView>)
}

export default FourthCircle
