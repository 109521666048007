import Papyrus from '../../components/Papyrus'
import { Col, Row, Image, ListGroup } from 'react-bootstrap'
import './index.css'
import ReactPlayer from 'react-player'
import seal from './seal.png'
import WhitelistForm from '../../components/WhitelistForm'
import brands from '../Impfluencer/brands/meta'
import roadmap from './roadmap.jpg'
import { useEffect } from 'react'

const WhitelistApplication = () => {

    useEffect(() => {
        document.title = 'The Imps - Whitelist Application'
    }, [])
    const listBrand = brands.map((brand) => {
        return <Col xs="6" md="4" lg="2">
            <a href={brand.url} target="_blank" rel="noreferrer">
                <Image src={brand.image} fluid />
            </a>
        </Col>
    })
    return (<>
        <Papyrus data="0" nav="/">
            <Row>
                <Col xs="12 text-left" md="12">
                    <br />
                    <br />
                    <h1 className="papyrus-title text-center">The Imps NFT: Genesis</h1>
                    <h4 className="papyrus-title text-center">
                        Escaping the Nine Circles of Hell to create the "Fallen Angels Club"
                    </h4>
                    <br /><br />
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col xs="12" md="5">
                    <WhitelistForm />
                </Col>
                <Col xs="12" md="4  ">
                    <h4 className="papyrus-title text-left">Facts: </h4>
                    <ListGroup className="papyruslist">

                        <ListGroup.Item className="">
                            <strong>Blockchain:</strong>  Ethereum
                        </ListGroup.Item>
                        <ListGroup.Item className="">
                            <strong>Token type:</strong> ERC-721
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>Total number of tokens:</strong>  6,666
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>Token withheld from sale:</strong> 66
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>Price per token:</strong> 0.0666 ETH
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>2nd market royalty fees:</strong> 6.66%
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>Reveal type:</strong> Post-launch
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong> Maximum number of traits per NFT:</strong> 15
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong> Unique +traits:</strong> 250+
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong> Whitelist spots:</strong> 2,222
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong> Max. NFT mints per wallet:</strong> 3
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>Payment methods:</strong> Ethereum & Creditcard pay
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong> Mint date:</strong> TBA
                        </ListGroup.Item>
                        {/* <ListGroup.Item>Website: https://the-Imps.com
                        </ListGroup.Item>
                        <ListGroup.Item> Whitepaper: https://the-imps.com/whitepaper.pdf
                        </ListGroup.Item>
                        <ListGroup.Item>Twitter: https://twitter.com/theimpsnftDiscord: https://discord.gg/fallenangelsclub
                        </ListGroup.Item> */}
                    </ListGroup>
                </Col>
            </Row>
            <Row>
                <Col xs="12 text-left" md="12">
                    <h1 className="title white-text text-center s1 mb-2">ABOUT THE IMPS</h1>
                    <h2 className="title text-center new-red mb-4">ROADMAP</h2>
                    <Image src={roadmap} fluid />
                    <br />
                    <br /><br />
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col xs="12 text-left" md="6">
                    <h1 className="title white-text text-center s1 mb-2">WHO MADE IT</h1>
                    <ReactPlayer url='https://www.youtube.com/watch?v=Oz2LSapR7ZM' className="vplayer" width="100%" />
                    <p className="papyrus-text">
                        <br />
                        The company behind The Imps NFT: German-based web 3.0 software developer Futurew3b
                    </p>
                    <br />
                </Col>
            </Row>
            <Row className="justify-content-md-center">

                <Col xs="12 text-left" md="6">
                    <p>
                        <i>... Born of the Devil's own flesh, the 6,666 Imps, mischievous demonic beings, are hardwired with an appetite for evil that can only be satiated by the continual torture of others. But, sick and tired of being relegated to menial duties by their Father, the Imps must now escape Hell, outwitting all of the Devil's gatekeepers along the way. To do so, they will rely on you, earthly beings who hold the utmost loyalty to evil. Through a series of tasks, you can help set them free. Together, we can pave the path to the creation of their Imp Kingdom and an age of destruction the world has never seen. Together, we will make Operation F.A.C. a success!...</i>
                    </p>
                    <ul className="papyruslist custom_bullet redlink">
                        <li>
                            Highly engaged <strong>Satire-RPG</strong> community and staff
                        </li>
                        <li>
                            <strong>Free Devil's Lean</strong> NFT airdrop: Combine both NFTs to transform your Imp
                        </li>
                        <li>
                            <strong>Launchpad</strong> for exclusive benefits for all web3.0 and web2.0 projects and brands, products, web applications, artists and corporate partners.
                            <br />
                            <a href="https://futurew3b.de/">futurew3b</a>
                        </li>
                        <li>
                            <strong>Implist community</strong> application platform: Customize your own role and apply to become a Devil's advocate
                        </li>
                        <li>
                            <strong>Utility quest</strong>: gather your Impgang and silence Cerberus
                        </li>
                        <li>
                            <strong>Doxxed</strong> team and smart contracts/provenance hashes
                        </li>
                        <li>
                            <strong>Imp idols</strong>: custom-made Imp sculptures
                        </li>
                        <li>
                            <strong>Merchstore</strong>: Infernal fashion and limited lootboxes: <a href="https://releeze.com/product/infernal-t-shirt-fallen-angels-club-RZ1435.2/">Infernal Merch</a>
                        </li>
                        <li>
                            <strong>Play2Burn NFT</strong> game: fight in the arena to purge other Imps for special rewards
                        </li>
                        <li>
                            <strong>Purgatory</strong>: get reborn as purged Imp after dying in the arena
                        </li>
                    </ul>
                    <p className="text-center redlink">
                        <a href="https://the-Imps.com">Website</a> - <a href="https://the-imps.com/whitepaper.pdf">Whitepaper</a><br />
                        <a href="https://twitter.com/theimpsnft">Twitter</a> - <a href="https://discord.gg/fallenangelsclub">Discord</a>
                    </p>
                    <br />
                </Col>
            </Row>
            <Row className="justify-content-md-center text-center">
                <Col xs="12 text-left" md="6">
                    <h2 className="title text-center s1 white-text">Our Partners</h2>
                    <Row className="justify-content-md-center">
                        {listBrand}
                    </Row>
                    <br />
                    <Row className="text-right">
                        <Col>
                            <Image src={seal} width="150px" />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Papyrus >
    </>)
}

export default WhitelistApplication
