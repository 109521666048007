
import { Container, Col, Row } from 'react-bootstrap'
import React, { useEffect } from 'react'
import './index.css'
function Impress () {
  useEffect(() => {
    document.title = 'The Imps - Imprint'
  })
  return (<div className="inner-content more-padding">
        <Container>
            <br /><br />
            <Row>
                <Col sm="12">
                    <h2 className="titleWhite">Impress</h2>

                    <p className="text"><strong>Provider identification:</strong></p>
                    <br /><br />
                    <p className="text">
                        <strong>FutureWeb GmbH</strong><br />
                        Veritaskai 3, 21079 Hamburg, Germany<br />
                        VAT: DE346715839<br />
                        Registry number: HRB 167447<br /><br /><br />

                        E-mail: hi@futurew3b.de<br />
                    </p>

                    <p className="text">
                        <strong>Technical Integration</strong><br />
                        Melexsoft | FutureWeb<br />
                        Veritaskai 3 , 21079 Hamburg, Germany<br /><br />
                    </p>
                </Col>
            </Row>
        </Container >
    </div>)
}

export default Impress
