import Papyrus from '../../components/Papyrus'
import { Row, Col, Form, Image, Table } from 'react-bootstrap'

import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { useEffect, useState } from 'react'
import BorderBox from '../../components/BorderBox'
import StoneButton from '../../components/StoneButton'
import money from './money.gif'
import gold from './gold.gif'

const Refereal = () => {
    const location = useLocation()
    const [wallet, setWallet] = useState(undefined)
    const [referral, setReferral] = useState(undefined)
    const host = process.env.REACT_APP_MAIN_API
    const api = '/api/v1/referrals/'
    const rankingApi = '/api/v1/rankings/'
    const [current, setCurrent] = useState(null)
    const [previous, setPrevious] = useState(null)
    const [next, setNext] = useState(null)
    const [pages, setPages] = useState(null)
    const [entries, setEntries] = useState(null)
    const [total, setTotal] = useState(null)
    const [noWhiteList, setNoWhitelist] = useState(false)
    const [ranking, setRanking] = useState(false)
    const [rankingPosition, setRankingPosition] = useState(0)
    const [currentReward, setCurrentReward] = useState(0)
    const rewards = [6666.00, 3750.00, 2500.00, 1250.00, 875.00, 875.00, 875.00, 750.00, 750.00, 750.00, 625.00, 625.00, 625.00, 625.00, 625.00, 500.00, 500.00, 500.00, 500.00, 375.00, 375.00, 125.00, 125.00, 125.00, 125.00]

    useEffect(() => {
        document.title = 'The Imps - Referral'
        const params = queryString.parse(location.search)
        if (params.wallet) {
            setWallet(params.wallet)
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            }
            fetch(host + api + params.wallet, requestOptions)
                .then((response) => response.json()).then((data) => {
                    if (data.status == 200) {
                        setReferral(data.referral)
                    } else {

                    }
                })
        }
        getRanking()
        get_list()
    }, [location, wallet])

    const getRanking = () => {
        if(wallet != null && wallet != undefined){
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            }

            fetch(host + rankingApi + wallet, requestOptions)
            .then((response) => response.json()).then((data) => {
                if (data.status == 200 && data.position > 0) {
                    setRankingPosition(data.position)
                    setCurrentReward(data.reward)
                    setRanking(true)
                } else {
                    setRanking(false)
                }
            })

        }else{
            setRanking(false);
        }
    }

    const getReferall = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }
        fetch(host + api + wallet, requestOptions)
            .then((response) => response.json()).then((data) => {
                if (data.status == 200) {
                    setReferral(data.referral)
                } else {
                    setNoWhitelist(true)
                }
            })
    }

    const get_list = (page = 1) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }
        fetch(host + api + '?page=' + page, requestOptions)
            .then((response) => response.json()).then((data) => {
                setCurrent(data.current)
                setPrevious(data.previous)
                setNext(data.next)
                setPages(data.pages)
                setEntries(data.entries)
                setTotal(data.total)
            })
    }

    const previousCall = () => {
        get_list(previous)
    }

    const nextCall = () => {
        get_list(next)
    }

    const checkWallet = () => {
        getReferall()
    }

    return (
        <>
            <Papyrus data="0" nav="/">
                <Row>
                    <Col xs="12 text-center">
                        <h1 className="papyrus-title">Hell's Referral Program</h1>
                        <Row>
                            <Col xs="3" md="2">
                                <Image src={money} fluid />
                            </Col>
                            <Col xs="9" md="8">
                                <p>Participate in The Imps NFT multi-level partner program and invite your friends and family to The Imps launch access! </p>
                                <p>Every time someone uses your unique invite link, you receive invite points, automatically rank up and increase your stake of the <strong>25.000 usd</strong> prize pool!</p>
                                <p>The top 100 are rewarded, whereby your stake increases the higher the rank you are. The top 1 receives 6,666.00 USD!</p>
                            </Col>
                            <Col xs="0" md="2" className="hideOnMobile">
                                <Image src={gold} fluid />
                            </Col>
                        </Row>
                        <br />
                        {referral && <>
                            <Row>
                                <Col xs="12" className="text-left">
                                    <h2 className="title white-text">1. PUT IN YOUR WALLET ADDRESS TO GENERATE YOUR UNIQUE AFFILIATE LINK</h2>
                                    <Form.Control type="text" value={wallet} onChange={(e) => setWallet(e.target.value)} placeholder="Your Wallet" disabled={true}></Form.Control>
                                    <br /><br />

                                </Col>
                                <Col xs="12" className="text-left">
                                    <Form.Control type="text" value={'https://www.the-imps.com/whitelistForm?referral=' + referral}></Form.Control>
                                    <p className="referral-sub-text">
                                        With your referral link generated for you, you can participate in the success of the Imps and be the first to share in the profits.
                                    </p>
                                </Col>
                            </Row>
                        </>}
                        {!referral && <>
                            <Row>
                                <Col xs="12" className="text-left">
                                    <Row>
                                        {noWhiteList && <>
                                            <Col xs="12">
                                                <Row>
                                                    <Col xs="12" className="text-center">
                                                        <p>Sorry you are not eligible to participate in the parter program, please check with the admin on our Discord.</p>

                                                        <StoneButton url="https://discord.com/invite/fallenangelsclub" realNav={true}>To Discord</StoneButton>
                                                        <br /><br /><br /><br />
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </>}
                                        {!noWhiteList && <>

                                            <Col xs="12">
                                                <h2 className="title white-text">1. PUT IN YOUR WALLET ADDRESS TO GENERATE YOUR UNIQUE AFFILIATE LINK</h2>

                                                <Form.Control type="text" value={wallet} onChange={(e) => setWallet(e.target.value)} placeholder="Your Wallet"></Form.Control>
                                                <br /><br />
                                            </Col>
                                            <Col xs="12">
                                                <StoneButton onClick={checkWallet}>Generate</StoneButton>
                                            </Col>
                                        </>}
                                    </Row>

                                </Col>
                            </Row>
                        </>}
                    </Col>
                </Row>

                <Row className="mt-5">
                    <Col xs="12 text-center">
                        <Row>
                            <Col lg={12}>
                                <h2 className="title text-center s1 white-text mb-4">RULES</h2>
                                <ul style={{ listStyle: 'none', marginBottom: '3rem' }}>
                                    <li><strong>Accepted Invites: </strong> 1 Point</li>
                                    <li><strong>Invitations with purchases: </strong> Purchases per Invites * 6 Points</li>
                                    <li><strong>MLM Bonus: </strong>You will recieve a portion of the points of your referrals.</li>
                                    <li><strong>Partner Payout: </strong>Half of the prize pool will be distributed to the top 25 partners. <br />The other half will be split between the rest depending on rank.<br />All rewards will be distributed after launch.</li>
                                    <li><strong>Winning Requirement: </strong>Minimum 1 Invite per wallet. </li>
                                </ul>
                                <h2 className="title text-center s1 white-text mb-4">LEADERBOARD</h2>
                                {ranking && <>
                                    <h3 className="title text-center refGreen">Current Rank: {rankingPosition}</h3>
                                    <h3 className="title text-center refGreen">Current Reward: {currentReward} $</h3>
                                </>}
                                <Table responsive className="text">
                                    <thead>
                                        <tr className="hideOnMobile">
                                            <th>Rank</th>
                                            <th>Wallet</th>
                                            <th>Accepted Invites</th>
                                            <th>Invitations with purchases</th>
                                            <th>Calculated Points</th>
                                            <th>Reward</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {entries && entries.map((entry, index) => {
                                            return (
                                                <tr className="divOnMobile" key={index}>
                                                    <td>
                                                        <span className="showOnMobile title white-text">
                                                            Rank:
                                                        </span> {index + 1 + ((current - 1) * 25)}</td>
                                                    <td>
                                                        <span className="showOnMobile title white-text">
                                                            Wallet Address:
                                                        </span>
                                                        <span className="showOnMobile">
                                                            {'0' + entry.address.substring(1, 5) + '...' + entry.address.substring(entry.address.length - 4, entry.address.length - 1)}
                                                        </span>
                                                        <span className="hideOnMobile">
                                                            {entry.address}
                                                        </span>

                                                    </td>
                                                    <td>
                                                        <span className="showOnMobile title white-text">
                                                            Accepted Invites:
                                                        </span> {entry.counter}</td>
                                                    <td><span className="showOnMobile title white-text">
                                                        Invitations with purchases:
                                                    </span> {entry.purchases_counter}</td>
                                                    <td>
                                                        <span className="showOnMobile title white-text">
                                                            Calculated Points:
                                                        </span>
                                                        {entry.koef}</td>
                                                    <td>
                                                        <span className="showOnMobile title white-text">
                                                            Reward:
                                                        </span> {index + 1 + ((current - 1) * 25) <= 25 ? rewards[index] : 0} $
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                            <Col lg={12} className="text text-center mt-5 onMobileSmaller">
                                {previous && <StoneButton onClick={previousCall}>Previous</StoneButton>} {current} / {pages} {next && <StoneButton onClick={nextCall}>Next</StoneButton>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Papyrus>
        </>
    )
}

export default Refereal
