import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import StoneButton from "../../components/StoneButton";

const Cancle = () => {
    return (<div className="inner-content">
      <Row className="justify-content-md-center">
            <Col xs="12" md="4" className="text-center errorpage">
                <h1 className="titleWhite">Mint canceled</h1>
                <br />
                <br />
                <p className="text-white text-bold">Damned demons! Unfortunately, dastardly Satan prevented your transaction from being completed. But don’t fret. If the Imps could wait centuries for their freedom, you can wait a few more minutes to join the rare Imp holder community. Keep trying. We promise it will be worth it!</p>
                <br />
                <br />
                <Row className="justify-content-md-center">
                <Col xs="12" md="6" className="text-center">
                <StoneButton url="/mint">Try Again</StoneButton> 
                <br /><br />                <br /><br />

                </Col>   
                <Col xs="12" md="6" className="text-center"> 
                <StoneButton url="/">Back to Startpage</StoneButton>
                </Col>
                </Row>
            </Col>
        </Row>
    </div>)
}


export default Cancle;