import snoop from './1.png'
import gary from './2.png'
import khaled from './3.png'
import marshmello from './4.png'
import mario from './5.png'
import trey from './6.png'
import kwame from './7.png'
import maxwel from './8.png'
import laura from './9.png'
import marioG from './10.png'
import montana from './11.png'
const impfluencers = [{
  id: 1,
  name: 'Maxwell187',
  image: maxwel,
  description: 'Slurp it! Drink it! smoke it! Smell it! <br /> Devil’s Lean ambassador.',
  instagram: 'https://www.instagram.com/p/CiiU930ofpU/'
}, {
  id: 2,
  name: 'Snoop Dogg',
  image: snoop,
  description: 'Rapper from West-Coast of the Hell.<br />Drop like its hot.',
  instagram: 'https://www.instagram.com/snoopdogg/'
},
{
  id: 3,
  name: 'Kwam.E',
  image: kwame,
  description: "You can hide, but you can't run! I'm coming from north. Has the voice of a fallen angel.",
  instagram: 'https://www.instagram.com/kwam.e'
},
{
  id: 4,
  name: 'Gary Veynerchuck',
  image: gary,
  description: 'Deals with the devil about winning the Super Bowl for the New York Jets.',
  instagram: 'https://www.instagram.com/garyvee/'
},
{
  id: 5,
  name: 'DJ Khaled',
  image: khaled,
  description: "They didn't believe in him, but the devil did. Another One.",
  instagram: 'https://www.instagram.com/djkhaled'
},
{
  id: 6,
  name: 'Marshmello',
  image: marshmello,
  description: 'There seemed to be some correlation between devotion to the devil and a misguided zeal for marshmallows.',
  instagram: 'https://www.instagram.com/marshmello'
},
{
  id: 7,
  name: 'Mario Bautista',
  image: mario,
  description: "I'll feed your skin snacks to my cocktail...<br />...Eyes like grapes. Delicious! ",
  instagram: 'https://www.instagram.com/mariobautista'
},
{
  id: 8,
  name: 'Trey Songz',
  image: trey,
  description: 'Let me feel your body sweating, I promise I’ll keep your body dripping.',
  instagram: 'https://www.instagram.com/treysongz/'
},
{
  id: 9,
  name: 'LaurDIY',
  image: laura,
  description: 'I wonder if I plant you in the ground, will you grow taller?',
  instagram: 'https://www.instagram.com/laurdiy'
},
{
  id: 10,
  name: 'Mario Götze',
  image: marioG,
  description: 'I made finger pizza just for you!<br />I always got my friends back!',
  instagram: 'https://www.instagram.com/mariogotze'
},

{
  id: 11,
  name: 'Montana Black',
  image: montana,
  description: "I'm the one who knocks!<br />GetOnMyLvL.",
  instagram: 'https://www.instagram.com/montanablack'
}
]

export default impfluencers
