import './index.css'
import React, { useEffect, useState } from 'react'
import StoneButton from '../StoneButton'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import f3 from './3.gif'
import futureWeb from './logo-futureweb.png'
import melexsoft from './logo-melexsoft.png'
import { Image, Nav } from 'react-bootstrap'
import discord from '../Navigation/discordicon.gif'
import twitter from '../Navigation/twittericon.gif'
import opensea from '../Navigation/openseaicon.gif'
import youtube from '../Navigation/tvicon.gif'
import soundStopped from '../Navigation/music_off.png'
import soundPlaying from '../Navigation/music_on.gif'
import { useCookies } from 'react-cookie'

function Footer ({ player }) {
  const [soundPlay, setSoundPlay] = useState(false)
  const [userIneract, setUserIneract] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies(['userInteraction'])
  const [navState, setNavState] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if ((cookies.userInteraction === undefined || cookies.userInteraction === false) && player && location && !soundPlay && !userIneract) {
      if (location.pathname === '/hell' && player) {
        console.log(location.pathname)
        player.play().then((result) => {
          setSoundPlay(true)
        })
      }
    }
  }, [soundPlay, location, navState])

  function togglePlayer (e) {
    setCookie('userInteraction', true, { path: '/' })
    setUserIneract(true)
    if (soundPlay) {
      player.pause()
      setSoundPlay(false)
    } else {
      player.play().then(() => {
        setSoundPlay(true)
      })
    }
  }

  return (
        <footer className="footer">
            <div className="container">
                <section className="row p-4">
                    <div className="col-lg-4 col-sm-12 d-none  d-md-block" >
                        <a href="https://discord.gg/fallenangelsclub" className='subnav' target="_blank" rel="noreferrer">
                            <Image src={discord} width="36px" />
                        </a>{' '}

                        <a href="https://twitter.com/theimpsnft" className="subnav" target="_blank" rel="noreferrer">
                            <Image src={twitter} width="36px" />
                        </a>{' '}

                        <a href="https://opensea.io/collection/the-imps-fallen-angels-club" className="subnav" target="_blank" rel="noreferrer"><Image src={opensea} width="36px" />
                        </a>{' '}

                        <a href="https://www.youtube.com/channel/UCZMBM5HLunOQlHaVKtuIM5Q" className="subnav tvIcon footer_tvIcon" target="_blank" rel="noreferrer">
                            <Image src={youtube} width="36px" />
                        </a>{' '}

                        <a className="subnav soundplayer footer_sound" onClick={togglePlayer}>
                            {soundPlay
                              ? <>
                                <Image src={soundPlaying} />
                            </>
                              : <>
                                <Image src={soundStopped} />
                            </>}
                        </a>

                    </div>

                    <div className="col-lg-4 col-sm-12 text-center">
                    <a  href="/roadmap.pdf" target="_blank">Roadmap</a    > | <Link to="/legal">Legal Notice</Link> | <Link to="/disclaimer">Disclaimer</Link> | <Link to="/referral">Referral Program</Link>
                    </div>
                    <div className="col-lg-4 col-sm-12 text-right d-none   d-md-block">
                        <a href="https://futurew3b.de/" target="_blank" className="tvIcon" rel="noreferrer" >
                            <Image src={f3} width="36px" /><span className="navItemDescription">Futureweb</span>
                        </a>
                    </div>
                </section>
            </div>
        </footer>)
}

export default Footer
