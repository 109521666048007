import { useCookies } from 'react-cookie'
import { useState, createContext } from 'react'
import useWeb3Hooks from '../hook/web3Hooks'

const AppContext = createContext({
  connected: false,
  wallet: '',
  metamaskInstalled: false,
  setConnected: () => { },
  setWallet: () => { },
  connectWeb3: () => { },
  disconnectWeb3: () => { },
  deeplink: 'https://metamask.app.link/dapp/www.the-imps.com/mint'
})

const AppContextProvider = (props) => {
  const [connectedCookie, setConnectedCookie, removeConnectCookie] = useCookies(['connected'])
  const [walletCookie, setWalletCookie, removeWalletCookie] = useCookies(['wallet'])
  const web3Hooks = useWeb3Hooks();
  const [connected, setConnected] = useState(connectedCookie.connected || false)
  const [wallet, setWallet] = useState(walletCookie.wallet || '')
  const metamaskInstalled = window.ethereum !== undefined || window.web3 !== undefined;
  const deeplink = 'https://metamask.app.link/dapp/www.the-imps.com/mint';
  const disconnectWeb3 = async () => {
    await removeConnectCookie('connected');
    await removeWalletCookie('wallet');
    await setConnected(false);
    await setWallet('')
  }

  const connectWeb3 = async () => {
    if (metamaskInstalled) {
      let accounts = await web3Hooks.getAccounts()
      if (accounts && accounts.length > 0) {
        await setConnected(true)
        await setWallet(accounts[0])
        await setWalletCookie('wallet', accounts[0]);
        await setConnectedCookie('connected', true);
      }
    } else {
      openMetaMaskUrl('https://metamask.app.link/dapp/www.the-imps.com/mint')
    }
  }

  function openMetaMaskUrl(url) {
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  return (
    <AppContext.Provider value={{
      connected,
      setConnected,
      wallet,
      setWallet,
      metamaskInstalled,
      connectWeb3,
      disconnectWeb3,
      deeplink
    }}>
      {props.children}
    </AppContext.Provider>
  )
}

const useAppContext = () => {
  return [AppContextProvider, AppContext]
}

export default useAppContext
