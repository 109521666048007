import { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Table } from 'react-bootstrap'

const WhitelistList = () => {
  const host = process.env.REACT_APP_MAIN_API
  const api = '/api/v1/whitelist_list'

  const [current, setCurrent] = useState(null)
  const [previous, setPrevious] = useState(null)
  const [next, setNext] = useState(null)
  const [pages, setPages] = useState(null)
  const [entries, setEntries] = useState(null)
  const [total, setTotal] = useState(null)

  const get_list = (page = 1) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }
    fetch(host + api + '?page=' + page, requestOptions)
      .then((response) => response.json()).then((data) => {
        setCurrent(data.current)
        setPrevious(data.previous)
        setNext(data.next)
        setPages(data.pages)
        setEntries(data.entries)
        setTotal(data.total)
      })
  }

  const previousCall = () => {
    get_list(previous)
  }

  const nextCall = () => {
    get_list(next)
  }

  useEffect(() => {
    get_list()
  }, [])

  return (<>
        <Container style={{ paddingTop: '10rem', color: 'white' }}>
            <Row>
                <Col lg={12} className="text text-center">
                    {previous && <Button onClick={previousCall}>Vorherige</Button>} Total {total} Whitelists {current} / {pages} {next && <Button onClick={nextCall}>Nächste</Button>}
                </Col>
                <Col lg={12}>
                    <Table className="text">
                        <thead>
                            <tr>
                                <th>Wallet</th>
                                <th>Quelle</th>
                                <th>Email</th>
                                <th>Discord</th>
                                <th>Twitter</th>
                                <th>Kommentar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {entries && entries.map((entry) => {
                              return (
                                    <tr>
                                        <td>{entry.address}</td>
                                        <td>{entry.source}</td>
                                        <td>{entry.email}</td>
                                        <td>{entry.discord}</td>
                                        <td>{entry.twitter}</td>
                                        <td>{entry.extra}</td>
                                    </tr>
                              )
                            }
                            )}
                        </tbody>
                    </Table>
                    <br />

                    <br />
                    <br />
                    <br />
                    <br />
                </Col>
            </Row>
        </Container>
    </>)
}

export default WhitelistList
