import image from './limbo.gif'
import ConainerInView from '../ContainerInView'

function Limbo () {
  return (<ConainerInView image={image} buttonText={'Enter'} target={'https://futurew3b.de/'} realNav={true}>
        <h2 className="titleWhite">1st Circle: Limbo</h2>
        <p className="text">
            Original sin afflicts us all,<br />
            Though some chose God, and some find the fall.<br />
            Unbaptized or pagan, no matter scope,<br />
            Those in Limbo have lost all their hope.<br />
            For those who hold the Imps NFT,<br />
            Futurew3b benefits will exist for thee.    <br /><br />
        </p>
    </ConainerInView>)
}

export default Limbo
