
import Synopsis from '../../components/Synopsis'
import './index.css'
import React, { useEffect } from 'react'
import brands from '../Impfluencer/brands/meta'
import { Col, Container, Image, Row } from 'react-bootstrap'
import roadmap from '../WhitelistApplication/roadmap.jpg'
import topf from './topf.gif' 
import raritysniper from './raritysniper.png'
import StoneButton from '../../components/StoneButton'
function Startpage() {
  useEffect(() => {
    document.title = 'The Imps - The Fallen Angels'
  })


  return (<>
    <div className="inner-content more-padding">
      <Synopsis></Synopsis>
      <Container>
        <Row className="mt-5">
          <Col>
            <a href="https://raritysniper.com/nft-drops-calendar">
              <Image src={raritysniper} width="100px" />
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  </>)
}

export default Startpage
