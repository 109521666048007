
import './index.css'

const BorderBox = ({ children, reverse = false }) => {
  return (
        <div className={reverse ? 'border-reverse-box' : 'border-box'}>
            {children}
        </div>
  )
}

export default BorderBox
