import './index.css'
import { Image, Row, Col, Container } from 'react-bootstrap'
import devilsLean from './header-devils-lean.gif'
import warlock from './header-the-warlock.gif'
import arena from './header-the-arena.gif'

import hand from './header-devils-hand.png'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import x from './x.svg'
function Papyrus ({ children, data = '0', nav }) {
  const navigate = useNavigate()

  function returnToRef () {
    navigate(nav)
  }

  const [header, setHeader] = useState(null)
  useEffect(() => {
    if (data === '3') {
      setHeader(warlock)
    } else if (data === '1') {
      setHeader(devilsLean)
    } else if (data === '2') {
      setHeader(arena)
    } else {
      setHeader(hand)
    }
  }, [header])

  return (<div className="inner-content blacktransparent">
        <Container>
            <Row>
                <Col>
                    <div className="papyrus">
                        <div className="papyrus-content">
                            <a onClick={returnToRef} className="closePapyrus"><Image src="/x.svg" width="36px"></Image></a>
                            <div className="papyrus-inner">
                                {children}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        </div>)
}

export default Papyrus
