
import { Container, Col, Row } from 'react-bootstrap'
import ReactPlayer from 'react-player'
import StoneButton from '../StoneButton'
import './index.css'
function Synopsis({ handleClick }) {
    return (<Container>
        <br /><br />
        <Row>
            <Col md="6" sm="12">
                <h2 className="titleWhite">Synopsis</h2>
                <ReactPlayer url='https://www.youtube.com/watch?v=5hvczGD2v48' className="vplayer" width="100%" />
                <br /><br />
            </Col>
            <Col md="6" sm="12">
                <div className="text-center">
                    <span className="title white-text mobile-text-center mobile-text-smaller">Press START to start the Game</span> <br /> <br />
                    <StoneButton url={'/hell'} scrollUp={true} bigger={true}>Start</StoneButton><br /><br />
                </div>
                <p className="text">I'm sorry to be the bearer of bad news, but, YOU'VE DIED, and now find yourself in the depths of Hell, living amongst the mischievous, cheeky Imps, 6,666 demonic beings, born at the hand of Satan, molded from his own flesh. </p>
                <p className="text">After centuries of being confined to servitude, they are ready to break free, and do their own thing. Like their Father, their appetite for pain and suffering is insatiable, and they are itching to wreak havoc on the world. But first, they must escape Hell, outwitting all of the Devil's chosen gatekeepers along the way. And they need your help. Through a series of tasks, you can help the Imps achieve their freedom, joining them as they pave a path to the creation of the Imp Kingdom, and an age of destruction the likes of which have never been seen... </p>
                <p className="text">Together, we will make Operation Fallen Angels Club a ghastly success! So join the dastardly demons and get started on the quest to break free through the nine circles of Hell!</p>
            </Col>
        </Row>
    </Container >)
}

export default Synopsis
