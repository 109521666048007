import fsagra from './fsagra.png'
import maxwell from './maxwell.png'
import releeze from './releeze.png'
import cculucci from './carlocolucci.png'
import illest from './theillest.png'

const brands = [{
  url: 'https://www.frantzisundsagra.de/',
  image: fsagra
},
{
  url: 'https://www.carlocolucci.com/',
  image: cculucci
},
{
  url: 'https://www.instagram.com/maxwell187erz',
  image: maxwell
},
{
  url: 'https://www.facebook.com/theillestpartyLG/',
  image: illest
},
{
  url: 'https://www.releeze.com/',
  image: releeze
}
]

export default brands
