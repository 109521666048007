import React, { useEffect, useState } from 'react'
import Papyrus from '../../components/Papyrus'
import { Button, Row, Col, Table, Image } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import vs from './vs.gif'
import StoneButton from '../../components/StoneButton'
import useWeb3Hooks from '../../hook/web3Hooks'
import TabNav from '../../components/TabNav'
import BorderBox from '../../components/BorderBox'

function Arena () {
  const navigate = useNavigate()
  const web3Hooks = useWeb3Hooks()
  const [connected, setConnected] = useState(false)

  useEffect(() => {
    document.title = 'The Imps - Fight in the Arena'
  })

  const connect = async () => {
    const accounts = await web3Hooks.getAccounts()
    if (accounts && accounts.length > 0) { setConnected(true) }
  }

  const myViews = {
    Purge: <>
            <p>The rules are simple.</p>
            <p>1. <span className="redColor">Stake</span> your Imps to receive the coveted <span className="redColor">blood diamond</span> rewards.</p>
            <p>2. Deploy 6 blood diamonds to execute a sneak attack on another Imp.</p>
            <p>3. Select the Imp you want to <span className="redColor">attack</span> and click on "Purge" button.</p>
            <p>4. For every Purged Imp you receive a "Tail" which later turns into a regrown imp.</p>
            <Row className="mt-5 mb-5">
                <Col xs={12} md={6}>
                    <BorderBox reverse={true}>
                        <Row>
                            <Col xs={12} md={4}>
                                <br /><Image src="/uimp.svg" fluid />
                            </Col>
                            <Col xs={12} md={4}>
                                <br />
                                <br />
                                <Image src="/vs.svg" fluid width="90px" />
                                <br />
                            </Col>
                            <Col xs={12} md={4}>
                                <br />
                                <Image src="/uimp.svg" fluid />
                            </Col>
                        </Row>
                    </BorderBox>
                </Col>
                <Col xs={12} md={6}>
                    <BorderBox>
                        <Row>
                            <Col xs={12} md={4}>
                                <br /><Image src="/uimp.svg" fluid />
                            </Col>
                            <Col xs={12} md={4}>
                                <br />
                                <br />
                                <br />

                                <Image src="/arrow.svg" fluid width="100px" />
                                <br />
                            </Col>
                            <Col xs={12} md={4}>
                                <br />
                                <Image src="/tail.svg" fluid />
                            </Col>
                        </Row>
                    </BorderBox>
                </Col>
            </Row>
            <Row className="mt-5 mb-5">
                <Col xs={12} md={6}>
                    <BorderBox reverse={true}>
                        <h4 className="papyrus-title">Available Imps to Purge</h4>
                        <p>No Imps are listed below 0.666 ETH on Opensea.</p>
                    </BorderBox>
                </Col>
                <Col xs={12} md={6}>
                    <BorderBox>
                        <h4 className="papyrus-title">Purge Leaderboard</h4>
                        <p>No Purge Leaders</p>
                    </BorderBox>
                </Col>
            </Row>
        </>,
    Fight: <>
            <p>Here you can Fight to Earn Blood Diamonds with another Imp.</p>
            <Row className="mt-5 mb-5">
                <Col xs={12} md={6}>
                    <BorderBox reverse={true}>
                        <h4 className="papyrus-title">Available Imps to Fight</h4>
                        <p>No Imps are open to fight</p>
                    </BorderBox>
                </Col>
                <Col xs={12} md={6}>
                    <BorderBox>
                        <h4 className="papyrus-title">Fighters Leaderboard</h4>
                        <p>No Fighting Leaders</p>
                    </BorderBox>
                </Col>
            </Row>
        </>,
    Stake: <>
            <p>You can get your Blood Diamonds by Staking your Imps</p>
            <Row className="mt-5 mb-5">
                <Col xs={12} md={6}>
                    <BorderBox reverse={true}>
                        <h4 className="papyrus-title">Available Imps to Stake</h4>
                        <p>No Imps are available to Stake</p>
                    </BorderBox>
                </Col>
                <Col xs={12} md={6}>
                    <BorderBox>
                        <h4 className="papyrus-title">Staking Rewards</h4>
                        <p>Current Reward 0.0 BLD</p>
                    </BorderBox>
                </Col>
            </Row>
        </>
  }

  return (<>
        <Papyrus data="0" nav="/hell#seventhCircle" >
            <Row>
                <Col xs="12 text-center">
                    <h1 className="papyrus-title">Arena</h1>
                    <TabNav slides={myViews}></TabNav>
                </Col>
            </Row>

        </Papyrus>
    </>)
}

export default Arena
