import React, { useContext, useEffect, useState } from 'react'
import { Button, Row, Col, Table, Image } from 'react-bootstrap'
import Papyrus from '../../components/Papyrus'
import tail1 from './t1.png'
import tail2 from './t2.png'
import tail3 from './t3.png'
import aggree from './agree.png'
import useWeb3Hooks from '../../hook/web3Hooks'
import { Link } from 'react-router-dom'
import useAppContext from '../../lib/app_context'

function Agreement () {

  const [_, AppContext] = useAppContext();
  const { connected, wallet, metamaskInstalled, connectWeb3, disconnectWeb3 } = useContext(AppContext);

  

  useEffect(() => {
    document.title = 'The Imps - Inside the Purfatory'
  }, [connected])

  return (<>
        <Papyrus data="0" nav="/hell/#nineth">
            <br />
            <Row>
              <Col xs="12 text-center">
                    <h1 className="papyrus-title">Purgatory</h1>
                    <Row>
                        <Col><Image src={tail1} fluid /></Col>
                        <Col><Image src={tail2} fluid /></Col>
                        <Col><Image src={tail3} fluid /></Col>
                    </Row>
                    <p>Do battle in the Arena, receiving Imptail NFT rewards as you do.</p>
                    <p>Select the Imptail NFT you wish to revive and find yourself reborn</p>
                    { connected ? <>
                      <p>No Imptail NFT in wallet detected</p>
                    </> : <>
                    <Link onClick={connectWeb3}>
                        <Image src={aggree} fluid width="150px" />
                    </Link>
                    </>}

                </Col>
            </Row>
        </Papyrus>
    </>)
}

export default Agreement
