import React, { useState, useEffect } from 'react'
import './index.css'
import logo from './logo.png'
import { Image } from 'react-bootstrap'

function Loader () {
  const [loading, setLoading] = useState(true)
  const [firstTime, setFirstTime] = useState(false)
  useEffect(() => {
    if (firstTime) return
    let timeOut = 1500
    if (window.location.pathname != '/') { timeOut = 500 }
    setFirstTime(true)
    setTimeout(() => {
      setLoading(false)
    }, timeOut)
  }, [])

  return (

        <div className={loading ? 'loader-container' : 'loader-container fadeOut'} >
            <div className="myCont">
                <Image src={logo} className="imageBoxLoader" /><br /><br />
                <div className="spinner" ></div >
            </div>
        </div>

  )
}

export default Loader
