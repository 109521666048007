import FirstCircle from '../../components/FirstCircle'
import SecondCircle from '../../components/SecondCircle'
import ThirdCircle from '../../components/ThirdCircle'
import FourthCircle from '../../components/FourthCircle'
import FifthCircle from '../../components/Fifth Circle'
import SixthCircle from '../../components/SixthCircle'
import SeventhCircle from '../../components/SeventhCircle'
import EigthCircle from '../../components/EigthCircle'
import Limbo from '../../components/Limbo'
import FallenAngelsClub from '../../components/FallenAngelsClub'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import ScrollSection from '../../components/ScrollSection'
import SnapScrollContainer from '../../components/SnapScrollContainer'
import ScrollNavigation from '../../components/ScrollNavigation'
import useOnScreen from '../../utils/use_on_screen'
import './index.css'

function Hell () {
  const location = useLocation()
  const navigate = useNavigate()
  const [refMap] = useState(new Map(Object.entries({
    '#fac': useRef(),
    '#firstCircle': useRef(),
    '#secondCircle': useRef(),
    '#thirdCircle': useRef(),
    '#fourthCircle': useRef(),
    '#fifthCircle': useRef(),
    '#sixthCircle': useRef(),
    '#seventhCircle': useRef(),
    '#eightCircle': useRef(),
    '#nineth': useRef()
  })))
  const [refSectionMap] = useState({
    '#fac': <FallenAngelsClub refTag={'#fac'} />,
    '#firstCircle': <Limbo refTag={'#firstCircle'}/>,
    '#secondCircle': <EigthCircle refTag={'#secondCircle'}/>,
    '#thirdCircle': <SeventhCircle refTag={'#secondCircle'}/>,
    '#fourthCircle': <SixthCircle refTag={'#fourthCircle'}/>,
    '#fifthCircle': <FifthCircle refTag={'#fifthCircle'}/>,
    '#sixthCircle': <FourthCircle refTag={'#sixthCircle'}/>,
    '#seventhCircle': <ThirdCircle refTag={'#seventhCircle'}/>,
    '#eightCircle': <SecondCircle refTag={'#eightCircle'}/>,
    '#nineth': <FirstCircle refTag={'#nineth'}/>
  })

  const [refArry, setRefArray] = useState(Array.from(refMap.keys()))
  const [isDirectScroll, setDirectScroll] = useState(true)

  function scrollTo (section) {
    if (isDirectScroll) {
      section.current.scrollIntoView({ behavior: 'auto' })
    } else {
      section.current.scrollIntoView({ behavior: 'smooth' })
    }
    setDirectScroll(true)
  }

  function scrollNowTo (hash) {
    setDirectScroll(false)
    navigate('/hell' + hash)
  }

  function handleScroll () {}

  useEffect(() => {
    document.title = 'The Imps - Escape the Hell'
    setRefArray(Array.from(refMap.keys()))
    const hash = location.hash

    // if not a hash link,  to top
    if (hash !== undefined) {
      if (refMap.get(hash)) {
        scrollTo(refMap.get(hash))
      }
    } else {
      window.scrollTo(0, 0)
    }
  }, [location, refSectionMap, refMap])

  return (<>
    <SnapScrollContainer onScroll={handleScroll}>
      {refArry.map(ref => {
        return (
          <ScrollSection key={ref}>
            <div ref={refMap.get(ref)} >
              {refSectionMap[ref]}
            </div>
          </ScrollSection>
        )
      })
      }
    </SnapScrollContainer>
  </>)
}

export default Hell
