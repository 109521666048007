import './index.css'
import Div100vh from 'react-div-100vh'

function SnapScrollContainer ({ children, onScroll }) {
  return (<Div100vh className="snappScrollContainer" onScroll={onScroll}>
            {children}
        </Div100vh>)
}

export default SnapScrollContainer
