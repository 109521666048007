import React, { useEffect, useState } from 'react'
import Papyrus from '../../components/Papyrus'
import { Col, Row, Button, Image } from 'react-bootstrap'
import advocat from './advocat.gif'
import './index.css'
import StoneButton from '../../components/StoneButton'
function Advocat () {
  const [showButton, setShowButton] = useState(true)
  useEffect(() => {
    document.title = 'The Imps - The Devils Advocat'
  })

  return (<>
        <Papyrus data="0" nav="/hell#secondCircle">
            <Row>
                <Col xs="12" className="text-center mb-5">
                    <h2 className="papyrus-title"><br />Apply as Devil's Advocat</h2>
                    <p className="papyrus-content-text">Enter the Application Center in hell and choose your own supportive role.<br />
                    If accepted, an impressive reward awaits...</p>
                    <a href="https://www.the-implist.com" target="_blank" rel="noreferrer"><Image src="/book.svg" fluid width="250px" /></a>
                    <br />
                    <p className="papyrus-content-text"> Click on the Book</p>
                </Col>
            </Row>
        </Papyrus>
    </>)
}

export default Advocat
