import Papyrus from '../../components/Papyrus'
import { Button, Row, Col, Image } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'

import impfluencers from './influencer/meta'
import members from './team/meta'
import Frame from '../../components/Frame'
import brands from './brands/meta'
import ImpSlider from '../../components/ImpSlider'

function Impfluencer () {
  const navigate = useNavigate()

  function returnToMain () {
    navigate('/')
  }

  useEffect(() => {
    document.title = 'The Imps - The Impfluencer'
  })

  const listImpfluencer = impfluencers.map((impf) => {
    return <Frame data={impf} canMint={true} />
  })

  const listTeam = members.map((impf) => {
    return <Frame data={impf} canMint={false} />
  })

  const listBrand = brands.map((brand) => {
    return <Col xs="6" md="4" lg="2">
            <a href={brand.url} target="_blank" rel="noreferrer">
                <Image src={brand.image} fluid />
            </a>
        </Col>
  })

  return (<>
        <Papyrus data="0" nav="/hell#fifthCircle">
            <br />
            <br />
            <Row>
                <Col xs="12" className="text-center">
                    <p>These NFTs are not for sale. Only the owner of the Brand, Influencer or teammember can mint.</p>
                    <h1 className="papyrus-title">Impfluencer</h1>
                </Col>
                <ImpSlider items={impfluencers} />
                <Col lg="12" className="text-center">
                    <p>If you are a celebrity and you also want your own Imp, contact us.</p>
                    <h2 className="papyrus-title">Our Team</h2>
                </Col>
                <ImpSlider items={members} />
                <Col lg="12" className="text-center">
                    <h2 className="papyrus-title">Our Brands</h2>
                </Col>
                {listBrand}
            </Row>

        </Papyrus>
    </>)
}

export default Impfluencer
