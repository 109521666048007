import image from './fifthCircle.gif'
import ConainerInView from '../ContainerInView'

function FifthCircle () {
  return (
        <ConainerInView image={image} buttonText={'Pact'} target={'/impfluencer'}>
            <h2 className="titleWhite">5th Circle: Waterfall of boiling blood</h2>
            <p className="text">Rage and anger, they fight, and they maul,<br />
                ‘Neath the depths of a gory red waterfall.<br />
                The Demon Baal is a diabolical myth,<br />
                And he presides over the Circle that’s Fifth.<br />
                Influencers along with a fully doxxed team,<br />
                Strategic partners, brands, we've created the dream.<br /><br />
            </p>
        </ConainerInView>)
}

export default FifthCircle
