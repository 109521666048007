import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import './navigation.min.css'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper'
import { useState, useEffect } from 'react'
import { Image } from 'react-bootstrap'
import './index.css'
import { Link } from 'react-router-dom'
const ImpSlider = ({ items }) => {
    console.log(items)

    return (<>
        <Swiper modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            spaceBetween={200}
            slidesPerView="auto"
            centeredSlides
            navigation
            loop
            autoplay={{
                delay: 4000
            }}>
            {items && items.map((slide) => (
                <SwiperSlide key={slide.id}>
                    <span className="slider-item-number">{slide.id}</span>
                    <div className="slider-content">
                        <span className="slider-separator slider-separator--number">
                            <span>{slide.id}</span>
                        </span>

                        <h3 className="slider-title">{slide.name}</h3>

                        <p dangerouslySetInnerHTML={{ __html: slide.description }} ></p>

                        <span className="slider-separator"></span>

                        <p className="slider-action-container">
                            {slide.instagram ? <>
                                <a href={slide.instagram} target="_blank"><i className="fa-brands fa-instagram" style={{ fontSize: "30px", color: '#801D24' }}></i> </a>
                            </> : null}{" "}
                            {slide.twitter ? <>
                                <a href={slide.twitter} target="_blank"><i className="fa-brands fa-twitter" style={{ fontSize: "30px", color: '#801D24' }}></i> </a>
                            </> : null}{" "}
                            {slide.linkedIn ? <>
                                <a href={slide.linkedIn} target="_blank"><i className="fa-brands fa-linkedin" style={{ fontSize: "30px", color: '#801D24' }}></i> </a>
                            </> : null}{" "}
                            {slide.youtube ? <>
                                <a href={slide.youtube} target="_blank"><i className="fa-brands fa-youtube" style={{ fontSize: "30px", color: '#801D24' }}></i> </a>
                            </> : null}{" "}
                            {slide.art ? <>
                                <a href={slide.art} target="_blank"><i className="fa-brands fa-artstation" style={{ fontSize: "30px", color: '#801D24' }}></i> </a>
                            </> : null}{" "}
                        </p>
                    </div>
                    <Image src={slide.image} fluid />
                </SwiperSlide>
            ))}
        </Swiper>
    </>)
}

export default ImpSlider
