import image from './fallenAngelsClub.gif'
import ConainerInView from '../ContainerInView'

function FallenAngelsClub () {
  return (<ConainerInView image={image} buttonText={'Mint'} target={'/mint'} firstElement={true}>
        <h2 className="titleWhite">Fallen Angels Club</h2>
        <p className="text">
            You’ve succeeded! The Circles of Hell have been beat,<br />
            And now you’re rewarded the sweetest of treats.<br />
            The journey was hard, it might have been painful,<br />
            But now you’re a part of the club of Fallen Angels.<br />
            Mint the Imps NFT and get Devil's Lean along the way,<br />
            Combine with the Imps for a Gen 2 Imp.<br /><br />
        </p>
    </ConainerInView>)
}

export default FallenAngelsClub
