import thirdCircle from './thirdCircle.gif'
import './index.css'
import ConainerInView from '../ContainerInView'

function ThirdCircle ({ refTag }) {
  return (
        <ConainerInView refTag={refTag} image={thirdCircle} buttonText={'Fight'} target={'/arena'} realNav={false}>

            <h2 className="titleWhite">7th Circle: Fledgiton valley</h2>
            <p className="text">The Seventh Circle is a valley of three,<br />
                Rivers of blood, and twisted trees.<br />
                Fledgiton they call it, where violence abounds,<br />
                Endless wails are the only sounds.<br />
                Stake your Imps for diamonds of blood,<br />
                Purge others with them; let them fall with a thud.<br /><br />
            </p>

        </ConainerInView>)
}

export default ThirdCircle
