import { Row, Col, Image } from 'react-bootstrap'
import graveyard from './gbg.svg'
import moon from './moon.svg'
import eyes from './eyes.gif'
import enter from './enter.gif'

import bubble from './bubble.svg';
import './index.css'
import { useEffect, useState } from 'react'
import scrollup from './arrowup.png'
import Div100vh from 'react-div-100vh'
import { useCookies } from 'react-cookie'

function SplashIntro () {
  const [hideGraveYard, setHideGraveYard] = useState(false)
  const [hideIntro, setHideIntro] = useState(false)
  const [startAnimation, setStartAnimation] = useState(false)
  const [cookies, setCookie] = useCookies(['viewSplash'])

  function enterGraveyard () {
    setStartAnimation(true)
    setTimeout(() => {
      setHideIntro(true)
      setCookie('viewSplash', true)
    }, 1500)
  }

  useEffect(() => {
    if (cookies.viewSplash) {
      setHideIntro(true)
    }
  }, [])

  return (<>
        {!hideIntro
          ? (
            <div className="splashIntro">
                {!hideGraveYard
                  ? (
                    <div className={ startAnimation ? 'blackscreen moveOut' : 'blackscreen'}>

                        <Div100vh>
                            <div className="graveyard">
                                <Image className="graveyard-bg" src={graveyard} />
                                <Row>
                                    <Col lg="12">
                                        <Image src={moon} className="moon-pic" />
                                        <div className="bubbleWrapper leftSide hideOnMobile">
                                          <Image src={eyes} className='eyes' />
                                          <Image src={bubble} className="bubble" width="400px"/>
                                          <p className="bubble-text">Congratulation on dying</p>
                                        </div>
                                        <div className="bubbleWrapper rightSide hideOnMobile">
                                        <Image src={eyes} className='eyes' />
                                          <Image src={bubble} className="bubble" width="400px"/>
                                          <p className="bubble-text">Press Enter to start your Adventure</p>
                                        </div>
                                        <div className="bubbleWrapper bubbleMobileView onlyMobile">
                                        <Image src={eyes} className='eyes' />
                                          <Image src={bubble} className="bubble" width="300px"/>
                                          <p className="bubble-text">Press Enter to start your Adventure</p>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="enterButton">
                                    <a onClick={enterGraveyard} >
                                        <Image src={enter} />
                                    </a>
                                </div>
                            </div>
                        </Div100vh>
                        </div>)
                  : null}
            </div>)
          : null} </>)
}

export default SplashIntro
