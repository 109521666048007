import secondCircle from './secondCircle.gif'
import ConainerInView from '../ContainerInView'

function SecondCircle () {
  return (<ConainerInView image={secondCircle} buttonText={'Enter Store'} target={'https://releeze.com/product/infernal-t-shirt-fallen-angels-club-RZ1435.1/'} realNav={true}>
        <h2 className="titleWhite">8th Circle: Malbolge</h2>
        <p className="text">
            Malborger they named the Eighth,<br />
            Home to seducers who betrayed others’ faith.<br />
            Belphegor is the watchman they laud,<br />
            Punishing all those sinners of fraud. <br />
            Loot boxes, infernal fashion,
            and more, <br />Await at the Warlock merchandise store. <br /> <br />
        </p>
    </ConainerInView>)
}

export default SecondCircle
