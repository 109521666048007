import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Image } from 'react-bootstrap'
import StoneButton from '../StoneButton'
import { useEffect, useRef, useState } from 'react'
import useOnScreen from '../../hook/useOnScreen'
import { useNavigate } from 'react-router-dom'
import scrollDown from './arrowdown.png'
import './index.css'

function ConainerInView ({ children, image, buttonText, target, realNav = false, firstElement = false }) {
  return (<Container height="100hv">
        <Row>
            <Col xs="12" md="6" className="flyingLevel">
                <Image src={image} alt="logo" fluid />
            </Col>
            <Col xs="12" md="6" className="mobile-text-center">
                {children}
                <StoneButton url={target} realNav={realNav}>{buttonText}</StoneButton>
                {
                    firstElement && <span className="text animatedScrollDown"><Image src={scrollDown} width={'30px'} /> Scroll Down</span>
                }

            </Col>
        </Row>
    </Container>)
}

export default ConainerInView
