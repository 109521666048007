import Papyrus from '../../components/Papyrus'
import { Col, Row, Image } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import note from './deathnote.gif'
import React, { useEffect } from 'react'
import './index.css'
import BorderBox from '../../components/BorderBox'
import provenance from './provenance'


function Provenance() {
    useEffect(() => {
        document.title = 'The Imps - Provenance Hash'
    })
    return (<>
        <Papyrus data="0" nav="/hell#fourthCircle">
            <Row className="justify-content-md-center">
                <Col xs="12" md="8" className="text-center">
                    <h1 className="papyrus-title"><br />Death Note</h1>
                    <p>
                        The demon whose name is written in this note shall exist<br />

                        This note will not take effect unless the writer has the demon’s <br />
                        face in their mind when writing the death contract-If the demon’s contract is written inside, <br />
                        they will come to existence within 6 seconds.</p>

                    <p>This page presents the provenance record of each Imp that will ever exist.
                        Each IMP image feature is firstly hashed using SHA-1 algorithm.
                        A combined string is obtained by concatenating SHA-1 of each Image Hash in the specific order as listed below.
                        The final proof is obtained by SHA-1 hashing this combined string.
                        This is the final provenance record stored on the smart contract</p>
                    <br /><br /><br />
                </Col>
            </Row>
            <Row>
                <Col xs="12" md="6" className="text-center">
                    <BorderBox reverse={true}>
                        <h4>Relevant information:</h4>
                        <p>
                            FINALIZED STARTING INDEX: TBA<br />
                            THE IMPS CONTRACT ADDRESS: TBA<br />
                            FINAL PROOF HASH: 32d4be36b5234825722463c387684836a3bb0d2e65807228f85bdb4cbb807185<br />
                        </p>
                    </BorderBox>
                </Col>
                <Col xs="12" md="6" className="text-center">
                    <BorderBox>
                        <h2>IMPORTANT INFORMATION</h2>
                        <p>
                            Each IMP token ID is minted from the initial sequence with this formula:<br />
                            (((tokenId + startingIndex) % 6666) + 1) → Initial Mint Sequence
                        </p>
                    </BorderBox>
                </Col>
                <Col xs="12" md="12" className="text-center">
                    <BorderBox>
                        <React.Fragment>
                            <h2>Provenance</h2>
                            <textarea style={{ width: "100%", display: 'block', height: '400px', background: 'transparent', color: "white", border: 'none' }}>{provenance}</textarea>
                        </React.Fragment>
                    </BorderBox>
                </Col>
            </Row>
        </Papyrus>
    </>)
}

export default Provenance
