
import firstCircle from './firstCircle.gif'
import ConainerInView from '../ContainerInView'

function FirstCircle () {
  return (<ConainerInView image={firstCircle} buttonText={'Agree'} target={'/agreement'} lastElement={true}>
        <h2 className="titleWhite">9th Circle: Frozen centre of hell</h2>
        <p className="text">
            The Ninth Circle presents the first test.<br />
            The Center of Hell, guarded by the best.<br />
            Redeem your Imptail NFT and be reborn in purgatory.<br />
        </p>
    </ConainerInView>)
}

export default FirstCircle
