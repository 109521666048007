import { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import './index.css'
import stoneBackground from './stone.png'

function StoneButton ({ children, url, realNav = false, scrollUp = false, onClick = null, bigger = false, smaller=false }) {
  const [color, setColor] = useState('#ffffff')
  const [fontSize, setFontSize] = useState('25px')

  useEffect(() => {
    if (bigger) {
      setFontSize('35px')
    }
    if(smaller){
      setFontSize('18px')
    }

  }, [])

  function jumpTo () {
    if (scrollUp) {
      window.scrollBy({ top: 0, behavior: 'instantly' })
    }
    window.open(url, '_blank')
  }

  function executeAction () {
    onClick()
  }

  return (
        <>
            {onClick
              ? <a onClick={executeAction} className="stoneButton" style={{ backgroundImage: `url(${stoneBackground})`, color, fontSize }}>{children}</a>
              : <>
                    {realNav
                      ? <a onClick={jumpTo} className="stoneButton" style={{ backgroundImage: `url(${stoneBackground})`, color, fontSize }}>{children}</a>
                      : <Link to={url} className="stoneButton" style={{ backgroundImage: `url(${stoneBackground})`, color, fontSize }}>{children}</Link>}
                </>
            }
        </>
  )
}

export default StoneButton
