import Papyrus from '../../components/Papyrus'
import { Col, Row, Button, Image, Table, Form } from 'react-bootstrap'
import useAppContext from '../../lib/app_context'
import vs from './vs.gif'
import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import attack from './attack.png'
import './index.css'
import CerberusAttack from '../../components/CerberusAttack'
import StoneButton from '../../components/StoneButton'
function Cerberus() {
    const [_, AppContext] = useAppContext()
    const { connected, wallet, metamaskInstalled, connectWeb3, disconnectWeb3 } = useContext(AppContext);

    useEffect(() => {
        document.title = 'The Imps - Fight the Cerberus'
    }, [connected])
    return (<>
        <Papyrus data="0" nav="/hell#thirdCircle" >
            <br />
            <Row>
                <Col xs="12" className="text-center">
                    <h2 className="papyrus-title"><br />The Hounds of Hell</h2>
                    <p>Silence the deafening bark of the Cerebus with your Imp Gang and receive a mystery reward...<br /><br /></p>
                </Col>
                <Row className="justify-content-md-center">
                    <Col xs="12" md="8" className="text-center">
                        <Row>
                            <Col md="4" className="text-center">
                                <Image src="/imp_gang.svg" fluid />
                                {connected ? <><p style={{ fontSize: '20px' }}>You do not have enough imps: <span style={{ color: 'red', fontWeight: 'bold' }}>0/3</span></p></> :
                                    <>
                                    <br />                                    <br />

                                        <Link onClick={() => { connectWeb3() }}>
                                            <Image src={attack} width="150px" fluid />
                                        </Link>
                                    </>}
                            </Col>
                            <Col md="4" className="text-center">
                                <br />
                                <Image src={vs} width="150px" />
                                <br />
                                <br />

                            </Col>
                            <Col md="4" className="text-center">
                                <Image src="/cerberus.svg" fluid />
                            </Col>
                        </Row>
                    </Col>

                </Row>

            </Row>
        </Papyrus>
    </>)
}

export default Cerberus
