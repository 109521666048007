import image from './sixthCircle.gif'
import ConainerInView from '../ContainerInView'

function SixthCircle () {
  return (<ConainerInView image={image} buttonText={'Read'} target={'/provenance'}>
        <h2 className="titleWhite">4th Circle: Gold mountains</h2>
        <p className="text">
            Mammon lets the wasteful and greedy trade barbs,<br />
            Gold mountains surround them, like imprisoning bars.<br />
            Some must know, “Why must they keep?”<br />
            While others spend always, right through their sleep.<br />
            Doxxed smart contract and provenance hash,<br />
            We hide nothing, yes we're that brash.<br /><br />
        </p>
    </ConainerInView>)
}

export default SixthCircle
