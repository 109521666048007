
import { useEffect, useState } from 'react'
import './index.css'

const TabNav = ({ slides }) => {
  const navigation = Object.keys(slides)
  const [active, setActive] = useState(navigation[0])

  const activeState = (state) => {
    return active == state ? 'nav-link active' : 'nav-link'
  }

  const setTabNav = (state) => {
    setActive(state)
  }

  const visibility = (state) => {
    return active == state ? 'show-tab' : 'hide-tab'
  }

  useEffect(() => {

  }, [navigation])
  return (
        <>
            <ul className="nav nav-tabs hell-tabs">
                {navigation.map((e) => {
                  return (
                        <li className="nav-item">
                            <a className={activeState(e)} aria-current="page" onClick={() => setTabNav(e)}>{e}</a>
                        </li>)
                })}
            </ul>
            {navigation.map((e) => {
              return (
                    <div className={visibility(e)}>
                        {slides[e]}
                    </div>)
            })}
        </>
  )
}

export default TabNav
