import philip from './blackphillip.jpg'
import writer from './ghostwriter.jpg'
import fero46 from './darkcode.jpg'
import mrbitcoin from './blackheart.jpg'
import snark from './the_snark.jpg'
import einfachari from './hellbender.jpg'
import i3dd3i from './hellcat.jpg'
import lawyer from './imp_lawyer.jpg'
import sond from './the_reaper.jpg'
import andyxhh from './souleater.jpg'
import goodguyfilms from './darkeye.jpg'
import nirami from './firedancer.jpg'
import Oxdeadcat from './0xdeadcat.jpg'
import belphegor from './rottenseed.jpg'
const impfluencers = [{
  id: 1,
  name: 'Black Phillip',
  image: philip,
  description: 'Head Admin/Product Owner',
  linkedIn: 'https://www.linkedin.com/in/suleimann-rahmani-102bb8166/'
},
{
  id: 2,
  name: 'Ghostwriter',
  image: writer,
  description: 'Content Marketing Concil',
  linkedIn: 'https://www.linkedin.com/in/scottmj/'
},
{
  id: 3,
  name: 'Darkcode',
  image: fero46,
  description: 'Co-Founder & Lead Dev',
  linkedIn: 'https://www.linkedin.com/in/ferhatziba/',
  twitter: 'https://www.twitter.com/fero46',
  instagram: 'https://www.instagram.com/fero46'
},
{
  id: 4,
  name: 'Blackheart',
  image: mrbitcoin,
  description: 'Co-Founder & Strategic Partnership Manager',
  instagram: 'http://instagram.com/firat46'
},
{
  id: 5,
  name: 'The Snark',
  image: snark,
  description: 'Art Director, 2D Artist & Animator',
  art: 'https://www.artstation.com/alcinna2/profile'
},
{
  id: 6,
  name: 'Hellbender',
  image: einfachari,
  description: 'Co-Founder & Strategic Partnership Manager',
  twitter: 'https://www.twitter.de/ArianFarahani'
},
{
  id: 7,
  name: 'i3dd3i',
  image: i3dd3i,
  description: '3D Artist, Graphic Design, 3D Print Expert',
  twitter: 'https://www.instagram.com/_i3dd3i_'
}, {
  id: 8,
  name: 'Imp Lawyer',
  image: lawyer,
  description: 'UX-Design, UI-Design, Usability Engineering',
  instagram: 'https://www.instagram.com/guvn/'
},
{
  id: 9,
  name: 'The Reaper',
  image: sond,
  description: 'Strategy & Marketing',
  linkedIn: 'https://www.linkedin.com/in/kevin-eich-237643138/',
  instagram: 'https://twitter.com/xsondx'
},
{
  id: 10,
  name: 'Souleater',
  image: andyxhh,
  description: 'Full Stack Developer',
  linkedIn: 'https://www.linkedin.com/in/andreaseppelein',
  instagram: 'https://instagram.com/andyxhh'
},

{
  id: 11,
  name: 'Goodimp',
  image: goodguyfilms,
  description: 'Creative Director, Film Producer, Director',
  instagram: 'https://www.instagram.com/goodguy_films/'
},
{
  id: 12,
  name: 'Nirami',
  image: nirami,
  description: 'Art Director, 2d Artist & Animator',
  youtube: 'https://www.youtube.com/c/Nirami'
},

{
  id: 13,
  name: 'OxDeadcat',
  image: Oxdeadcat,
  description: 'Strategy & Marketing'
},
{
  id: 14,
  name: 'Fireseed',
  image: belphegor,
  description: 'Trainee',
  youtube: 'https://youtube.com/channel/UCxhcPr7Qhd15JzwZt1XgYNw'
}
]

export default impfluencers
